$breadcrumb-divider: quote(">");

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: $breadcrumb-divider;
}