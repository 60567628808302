@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "../../theme";

.slider__parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider__main {
  border-radius: 0.25rem;
}
.slider__main--container {
  background-size: cover;
  // max-height: 350px;
}

.slider__main hr {
  border-color: $bullseye-red-color;
  border-width: 25px;
}

.slider__main--information {
  background: #ffffff;
  min-height: 100px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-top: -1px;
}
.slider__main--logoContainer {
  position: absolute;
  margin-top: 3% !important;
  margin-left: 5% !important;
  margin-right: 2rem !important;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: bounce-1;
  animation-timing-function: ease;
  min-height: 80px;
  max-height: 200px;
  display: flex;
  align-items: center;
}

.slider__main--logoContainer img {
  max-height: 200px;
  max-width: 200px;
  border: 10px solid #fff
}

@keyframes bounce-1 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.01, 0.99) translateY(0);
  }
  30% {
    transform: scale(0.99, 1.01) translateY(-10px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.slider__main--infoContainer {
  text-align: center;
  margin-bottom: 20px;
}

.slider__main--information {
  align-items: center;
}

@include media-breakpoint-up(md) {
  .slider__main--information {
    align-items: flex-start;
  }
  .slider__main--infoContainer {
    text-align: left;
    margin-bottom: 0;
  }
}
