.bgfooter-xcelaration {
  background-color: #707170;
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 34pt;
}
h2 {
  font-size: 26pt;
}
h3 {
  font-size: 16pt;
}
h4 {
  font-size: 12pt;
}

.body_c1 {
  font-size: 12pt;
}
.body_c2 {
  font-size: 10pt;
}

a {
  font-weight: 600;
}

.salesx__caseStudies--container svg {
  flex: 0 0 auto;
}
.salesx__caseStudies--container li {
  line-height: 1em !important;
  display: flex;
  align-items: center;
}
.salesx__caseStudies--container a {
  color: #000;
  font-size: 12pt;
  padding-left: 15px;
  flex: 1;
}

.salesx__card-body--container {
  background-color: #ebebeb;
}

footer a,
footer a:hover {
  color: #fff;
}
body {
  background-color: #eee;
}
.slider__main--infoContainer {
  color: #333;
}

.list-unstyled li {
  color: #c2002f;
}

.event__cardDate {
  width: 90px !important;
  min-height: 70px !important;
  border-width: 2px !important;
  font-weight: bold !important;
  font-size: 11px !important;
  background: #ebebeb;
}

.event__oneDate {
  font-size: 12px !important;
}

.navbar a:hover {
  font-weight: bold;
}

nav {
  background-color: #fff;
}

@media (min-width: 481px) {
  #btnTakeSalesASsessment {
    margin-right: 20px;
  }
}

@media (max-width: 481px) {
  .text-image {
    font-size: 20px;
  }
}

.salesx__caseStudies--container li {
  padding-top: 0.9em !important;
}
.caseStudies_li {
  line-height: 1em !important;
}

.blockquote__salesx {
  font-size: 1rem;
}

.card_sales {
  background: #ebebeb;
}

.icon_phoneNumber {
  position: absolute;
  margin-top: 12px;
  left: calc(100% - 54px);
  transform: translateY(-50%);
  white-space: nowrap;
}

.icon_email {
  position: absolute;
  margin-top: 12px;
  left: calc(100% - 46px);
  transform: translateY(-50%);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .icon_phoneNumber {
    position: absolute;
    top: 50%;
    margin-top: 0;
    left: 100%;
    transform: translateY(-50%);
  }

  .icon_email {
    position: absolute;
    top: 50%;
    margin-top: 0;
    left: calc(100% + 8px);
    transform: translateY(-50%);
  }
}
