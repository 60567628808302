@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xs) {
  .cardWithImage__img--maxSize {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .cardWithImage__img--maxSize {
    max-width: 30%;
  }
}
