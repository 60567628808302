.categoryTreeInput,
.categoryTreeInput .dropdown,
.categoryTreeInput .dropdown .dropdown-trigger,
.categoryTreeInput .tag-list {
  width: 100%;
}
.categoryTreeInput .tag-item {
  width: 90%;
}
.categoryTreeInput .tag-item .search {
  width: 98%;
}
.dropdown-content {
  width: 100%;
}
.node-label {
  white-space: normal !important;
}
