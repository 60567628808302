@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xs) {
  .showcase__card--maxSize {
    max-width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .showcase__card--maxSize {
    max-width: 220px;
  }
}
.showcase__widthSquare100 {
  width: 100px;
  height: 100px;
}

.showcase__widthSquare50 {
  width: 50px;
  height: 50px;
}

.showcase__img--sizeW50 img {
  width: 50px;
}

.showcase__img--sizeW100 img {
  width: 100px;
}
