.g-recaptcha__dynamicForm {
  transform: scale(0.91);
  transform-origin: 0 0;
}

.invalid-feedback-xl{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 1.6rem;
  color: #dc3545;
  text-align: center;
}

