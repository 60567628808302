@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.progressBar {
  background-color: rgba(95, 93, 94, 0.185);
  width: 100%;
  height: 10px;
  padding: 3px;
  float: left;
  margin-right: 3px;
}
.progressBar.fill {
  background-color: #ffd700;
}
