@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
// $template2-color: #c50518;

body {
  background-color: #fff;
}

.slider__main--logoContainer {
  margin-left: 5%;
  margin-top: -110px;
  min-height: 120px;
}

// .bullseye-red {
//   color: $template2-color;
// }

// .border-bullseye {
//   border-color: $template2-color;
// }

// .slider__main hr {
//   border-color: #ffc511;
// }

// svg {
//   fill: #ffc511;
//   color: #ffc511;
// }

// svg:hover {
//   fill: darken(#ffc511, 15%);
//   color: darken(#ffc511, 15%);
// }

// svg.nohover:hover {
//   fill: $template2-color;
//   color: $template2-color;
// }

// a {
//   color: $template2-color;
// }

// a:hover {
//   color: darken($template2-color, 15%);
// }

.headerContainer__container .card {
  border: 0;
  box-shadow: none !important;
}

.mainBodyContainer__container .card {
  border: 0;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.slider__main--logoContainer img {
  border-radius: 50% !important;
}

@include media-breakpoint-up(md) {
  .slider__main--infoContainer {
    margin-top: -80px;
    color: #fff;
  }
  .slider__main--infoContainer h2 {
    margin-bottom: 50px;
  }
}
.slider__main--information {
  min-height: 60px;
}
