.locationInformation__img--size-150 {
  max-width: 150px;
}

.locationInformation__img--size-300 {
  max-width: 300px;
  max-height: 250px;
}

.locationInformation__locationTypeContainer img {
  max-width: 100px;
  max-height: 100px;
}

.locationInformation__directions, .locationInformation__phone, .locationInformation__email, .locationInformation__url {
  min-width: 105px;
}