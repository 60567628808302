.pin {
  display: flex;
  align-items: center;
  margin-left: -1rem;
  margin-top: -2.25rem;
}

.pin img {
  margin-right: 10px;
  max-width: 46px;
}

.pin p {
  color: rgb(202, 8, 8);
  font-weight: 800;
  font-size: 0.75rem;
}

// .pinHover {
//   transform: scale(1, 1);
//   filter: contrast(1);
//   position: absolute;
//   width: 39px;
//   height: 44px;
//   will-change: transform;
//   background-size: 39px 44px;
//   background-repeat: no-repeat;
//   transition: -webkit-transform 0.25s cubic-bezier(0.485, 1.65, 0.545, 0.835) 0s;
//   transform-origin: 24.5px 64px;
//   z-index: 9980;
// }

.gm-style div {
  border: 0 !important;
  font-size: 0.8rem;
}

.cluster-marker {
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}