.event__cardDate {
  width: 110px;
  min-height: 110px;
  border-width: 2px;
  font-weight: 500;
}

.event__oneDate {
  font-size: 1.3rem !important;
  align-self: center;
}

.list-border-bottom:not(:last-child) {
  border-bottom: 1px solid #dee2e6 !important;
}

.event__description-Container {
  word-break: break-all;
}